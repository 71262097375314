import { Button, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import * as React from 'react'
import AppPageWraper from '../../../../components/PageWrappers/AppPageWraper'
import OverviewList from '../../../../components/Bank/OverviewList'
import { ExternalLink } from '../../../../components/BlogPost/Texts'

function BankingIntegrationPage() {
    return (
        <AppPageWraper>
            <Typography variant="h6">Automatiser dine bilag!</Typography>
            <Typography variant="body1">
                Her kan du tilføje din bank konto til din profil og automatisk få oprettet dine køb
                som bilag.
                <br />
                <br />
                <Typography component="span" sx={{ textDecoration: 'underline' }}>
                    ll33a.dk har{' '}
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>
                        ikke licens
                    </Typography>{' '}
                    til at udøve kontooplysningstjenester.
                </Typography>{' '}
                Denne feature er en test-version og før du kan benytte den skal du være indforstået
                med at{' '}
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    du er test-bruger.
                </Typography>
                <br />
                Inden denne feature går i produktion skal ll33a.dk og den bagved liggende
                virksomhed, Ikenson ApS, opnå licens som kontooplysningstjenesteudbyder. Læs om det
                her:{' '}
                <ExternalLink to="https://www.finanstilsynet.dk/Lovgivning/Information-om-udvalgte-tilsynsomraader/Betalingstjenester-og-e-penge/Orientering-om-fortolkning-af-definitionen-kontooplysningstjenester">
                    finanstilsynets fortolkning
                </ExternalLink>
            </Typography>
            <OverviewList />
        </AppPageWraper>
    )
}

export default BankingIntegrationPage
