import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import { makeStyles } from '@mui/styles'
import CardMedia from '@mui/material/CardMedia'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import ListItemButton from '@mui/material/ListItemButton'
import { navigate } from 'gatsby'
import { Account } from '../../types/banks'

const useStyles = makeStyles({
    bankCard: {
        width: '100%',
    },
    balanceText: {
        textAlign: 'center',
        justifyContent: 'center',
    },
    contentGrid: {
        width: '100%',
    },
})

const listItemImageSx = { width: 100 }

function ListItem({ account }: { account: Account }) {
    const classes = useStyles()

    function redirectToAcount() {
        navigate(`/app/banking/integrations/bank-account?account_id=${account.id}`)
    }

    return (
        <Card className={classes.bankCard} sx={{ display: 'flex' }} onClick={redirectToAcount}>
            <ListItemButton>
                <CardMedia
                    component="img"
                    sx={listItemImageSx}
                    image={account?.bank_institution?.logo_url}
                    alt="Intitution logo"
                />
                <CardContent>
                    <Grid className={classes.contentGrid} container spacing={0}>
                        <Grid item xs={8}>
                            <Box>
                                <Typography component="div" variant="body1">
                                    {account.name || account.owner_name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    {account.product}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    Inclusion: {account.inclusion_percentage}%
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                className={classes.balanceText}
                                variant="h6"
                                component="div"
                            >
                                {account.balance} {account.currency}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </ListItemButton>
        </Card>
    )
}

export default ListItem

export function AddInstitutionItem() {
    const classes = useStyles()

    async function addIntegration() {
        navigate('/app/banking/integrations/pick-bank-country')
    }

    return (
        <Card className={classes.bankCard} sx={{ display: 'flex' }}>
            <ListItemButton onClick={addIntegration}>
                <CardContent sx={listItemImageSx}>
                    <AddIcon sx={{ fontSize: '48px' }} />
                </CardContent>
                <CardContent>
                    <Box>
                        <Typography component="div" variant="body1">
                            Add an institution
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            For example your bank account
                        </Typography>
                    </Box>
                </CardContent>
            </ListItemButton>
        </Card>
    )
}
