import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import IntegrationsListItem, { AddInstitutionItem } from './OverviewListItem'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { BanksPaper } from './paperComponents'
import { getAccounts, refreshAccounts } from '../../api/banks'
import { Account } from '../../types/banks'
import Stack from '@mui/material/Stack'
import RefreshIcon from '@mui/icons-material/Refresh'
import LoadingButton from '@mui/lab/LoadingButton'
import LinearProgress from '@mui/material/LinearProgress'
import createAlertSnackbar from '../dialogs/snackbars'

const StyledListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

function OverviewList() {
    const [accounts, setAccounts] = React.useState<Account[]>([])
    const [refreshLoading, setRefreshLoading] = React.useState<boolean>(false)
    const [fetchLoading, setFetchLoading] = React.useState(true)
    const { openAlert, AlertComponent } = createAlertSnackbar('error')

    useEffect(() => {
        getAccounts().then((accounts: Account[]) => {
            setAccounts(accounts)
            setFetchLoading(false)
        })
    }, [])

    async function refreshIntegrations() {
        setRefreshLoading(true)
        try {
            await refreshAccounts()
            setFetchLoading(true)
            const accounts = await getAccounts()
            setAccounts(accounts)
        } catch (e) {
            openAlert(e.toString(), 'error')
        }
        setRefreshLoading(false)
        setFetchLoading(false)
    }

    return (
        <BanksPaper>
            {AlertComponent}
            <Stack direction="row" spacing={2} sx={{ marginBottom: fetchLoading ? 2 : 5 }}>
                <Typography variant="overline" component="div" gutterBottom>
                    Integrations
                </Typography>
                <LoadingButton
                    size="small"
                    onClick={refreshIntegrations}
                    variant="outlined"
                    endIcon={<RefreshIcon />}
                    loading={refreshLoading}
                    loadingPosition="end"
                >
                    Refresh
                </LoadingButton>
            </Stack>
            {fetchLoading && <LinearProgress sx={{ marginBottom: 2 }} />}
            <List disablePadding>
                {accounts
                    .sort((a: Account, b: Account) => {
                        if (a.inclusion_percentage !== b.inclusion_percentage) {
                            return b.inclusion_percentage - a.inclusion_percentage
                        }
                        return b.balance - a.balance
                    })
                    .map((account: Account) => {
                        return (
                            <StyledListItem disablePadding>
                                <IntegrationsListItem account={account} />
                            </StyledListItem>
                        )
                    })}
                <StyledListItem disablePadding>
                    <AddInstitutionItem />
                </StyledListItem>
            </List>
        </BanksPaper>
    )
}

export default OverviewList
